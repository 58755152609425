.header{
    /* background: rgba(14, 29, 52, 0.9); */
    background-color: white;
    /* position: absolute; */
}
.topNavbar{
    margin-top: 35px;
}
.navbar li a{
    color: black;
}
.navbar li a:hover{
    color: green;
    font-weight: 600!important;
}
.activeNavbar{
  color: green!important;
    font-weight: 600!important;
}
.hero{
    background-color: white;
    
}
.homeh1{
    color: #211f54;
    font-family: Vollkorn, serif;
    font-size: 54px;
    font-weight: 700;
    line-height: 60px;
    white-space: normal;
}
.homeh2{
   
    /* color: #6e7191!important; */
    color: #211f54!important;
}
.servicesButtonHomePage{
    border: 1px solid #a99595;
    padding: 8px;
    width: 119px;
    color: #cdcdcd;
    background: #211f54;
    cursor: pointer;
}
.servicesButtonHomePage:hover{
  
    color: #211f54!important;
    background:  #cdcdcd !important;
}
.webLogoName{
    color: #7f7c7c !important;
    /* color: green !important; */
    font-size: 29px;
    margin-left: 27px;
    font-family: auto;
    position: absolute;
    bottom: -8px;
    position: relative;
}
.NavbarLogo{
    position: relative;
}
.NavbarLogo img{
  position: absolute;
  left: -113px;
  top: -43px;
  height: 103px;
  width: 259px;
  object-fit: cover;

}
/* .NavbarLogo img {
  position: absolute;
  left: -87px;
  top: -27px;
  height: 61px;
  width: 56px;
  object-fit: cover;
 
} */
.stretched-link{
    color: #211f54!important;
}
.ValueinHomePage{
    color: #0d2b7e;
}
.PricingContactColur{
    color: #211f54!important;
}
.services .card .card-img {
    text-align: center;
}
.secondsectionHome{
    display: flex;
    padding: 20px;
}
.contactFirm{
    display: flex;
}
.infocontact{
    padding-left: 20px;
}
.contact {
    margin-top: -143px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
/* tyle={{border: "0",width: "70%",height: "340px"}} */
.mapcontact{
border: 0;
width: 70%;
height: 340px;
}
@media (max-width: 780px) {
    .contactFirm{
      
      flex-direction: column;
    }
    .mapcontact{
        border: 0;
        width: 100%;
        height: 340px;
        }
        .infocontact{
            padding-left: 0px;
            margin-top: 15px;
        }
        .NavbarLogo img {
          position: absolute;
          left: -44px;
          top: -144px;
          width: 304px;
      }
}
.langcol{
    border: 1px solid #a99595;
    padding: 8px!important;
    /* width: 119px; */
    text-align: center;
    margin-left: 14px;
    color: #cdcdcd !important;
    background: #211f54;
    cursor: pointer;
    /* width: 100px; */
    padding-left: 21px !important;
    padding-right: 21px!important;
    border-radius: 5px;
}
.langcol:hover{
  
    color: #211f54!important;
    background: #cdcdcd !important;
 
}
.mobile-nav-show {
    color: #270c0c !important;
}
.navmobb{
    /* right: 0!important; */
    top: 0!important;
    right: 0px!important;
    left: 0px!important;
}
.navmobb ul{
    background-color: #f5f5f5;
    color: white;
}
.navmobb ul li a{
    color: #262222!important;
    /* border: 1px solid #877e7e; */
    margin-bottom: -28px;
    margin-top: 25px;
    font-family: auto;
    font-size: 17px;
}
.navmobb ul li:last-child a{
    margin-left: 0!important;
    background: #98afb7;
    color: #0b144c!important;
  }
  .navmobb ul li:last-child {
 padding: 16px;
 text-align: left;
  }


  
/*--------------------------------------------------------------
# Blog
--------------------------------------------------------------*/
.blog {
    padding: 40px 0 20px 0;
  }
  
  .blog .entry {
    padding: 30px;
    margin-bottom: 60px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
  
  .blog .entry .entry-img {
    max-height: 440px;
    margin: -30px -30px 20px -30px;
    overflow: hidden;
  }
  
  .blog .entry .entry-title {
    font-size: 28px;
    font-weight: bold;
    padding: 0;
    margin: 0 0 20px 0;
  }
  
  .blog .entry .entry-title a {
    color: #012970;
    transition: 0.3s;
  }
  
  .blog .entry .entry-title a:hover {
    color: #4154f1;
  }
  
  .blog .entry .entry-meta {
    margin-bottom: 15px;
    color: #4084fd;
  }
  
  .blog .entry .entry-meta ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    align-items: center;
    padding: 0;
    margin: 0;
  }
  
  .blog .entry .entry-meta ul li+li {
    padding-left: 20px;
  }
  
  .blog .entry .entry-meta i {
    font-size: 16px;
    margin-right: 8px;
    line-height: 0;
  }
  
  .blog .entry .entry-meta a {
    color: #777777;
    font-size: 14px;
    display: inline-block;
    line-height: 1;
  }
  
  .blog .entry .entry-content p {
    line-height: 24px;
  }
  
  .blog .entry .entry-content .read-more {
    -moz-text-align-last: right;
    text-align-last: right;
  }
  
  .blog .entry .entry-content .read-more a {
    display: inline-block;
    background: #4154f1;
    color: #fff;
    padding: 6px 20px;
    transition: 0.3s;
    font-size: 14px;
    border-radius: 4px;
  }
  
  .blog .entry .entry-content .read-more a:hover {
    background: #5969f3;
  }
  
  .blog .entry .entry-content h3 {
    font-size: 22px;
    margin-top: 30px;
    font-weight: bold;
  }
  
  .blog .entry .entry-content blockquote {
    overflow: hidden;
    background-color: #fafafa;
    padding: 60px;
    position: relative;
    text-align: center;
    margin: 20px 0;
  }
  
  .blog .entry .entry-content blockquote p {
    color: #444444;
    line-height: 1.6;
    margin-bottom: 0;
    font-style: italic;
    font-weight: 500;
    font-size: 22px;
  }
  
  .blog .entry .entry-content blockquote::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 3px;
    background-color: #012970;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .blog .entry .entry-footer {
    padding-top: 10px;
    border-top: 1px solid #e6e6e6;
  }
  
  .blog .entry .entry-footer i {
    color: #0d64fd;
    display: inline;
  }
  
  .blog .entry .entry-footer a {
    color: #013289;
    transition: 0.3s;
  }
  
  .blog .entry .entry-footer a:hover {
    color: #4154f1;
  }
  
  .blog .entry .entry-footer .cats {
    list-style: none;
    display: inline;
    padding: 0 20px 0 0;
    font-size: 14px;
  }
  
  .blog .entry .entry-footer .cats li {
    display: inline-block;
  }
  
  .blog .entry .entry-footer .tags {
    list-style: none;
    display: inline;
    padding: 0;
    font-size: 14px;
  }
  
  .blog .entry .entry-footer .tags li {
    display: inline-block;
  }
  
  .blog .entry .entry-footer .tags li+li::before {
    padding-right: 6px;
    color: #6c757d;
    content: ",";
  }
  
  .blog .entry .entry-footer .share {
    font-size: 16px;
  }
  
  .blog .entry .entry-footer .share i {
    padding-left: 5px;
  }
  
  .blog .entry-single {
    margin-bottom: 30px;
  }
  
  .blog .blog-author {
    padding: 20px;
    margin-bottom: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
  
  .blog .blog-author img {
    width: 120px;
    margin-right: 20px;
  }
  
  .blog .blog-author h4 {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 0px;
    padding: 0;
    color: #012970;
  }
  
  .blog .blog-author .social-links {
    margin: 0 10px 10px 0;
  }
  
  .blog .blog-author .social-links a {
    color: rgba(1, 41, 112, 0.5);
    margin-right: 5px;
  }
  
  .blog .blog-author p {
    font-style: italic;
    color: #b7b7b7;
  }
  
  .blog .blog-comments {
    margin-bottom: 30px;
  }
  
  .blog .blog-comments .comments-count {
    font-weight: bold;
  }
  
  .blog .blog-comments .comment {
    margin-top: 30px;
    position: relative;
  }
  
  .blog .blog-comments .comment .comment-img {
    margin-right: 14px;
  }
  
  .blog .blog-comments .comment .comment-img img {
    width: 60px;
  }
  
  .blog .blog-comments .comment h5 {
    font-size: 16px;
    margin-bottom: 2px;
  }
  
  .blog .blog-comments .comment h5 a {
    font-weight: bold;
    color: #444444;
    transition: 0.3s;
  }
  
  .blog .blog-comments .comment h5 a:hover {
    color: #4154f1;
  }
  
  .blog .blog-comments .comment h5 .reply {
    padding-left: 10px;
    color: #012970;
  }
  
  .blog .blog-comments .comment h5 .reply i {
    font-size: 20px;
  }
  
  .blog .blog-comments .comment time {
    display: block;
    font-size: 14px;
    color: #013ca3;
    margin-bottom: 5px;
  }
  
  .blog .blog-comments .comment.comment-reply {
    padding-left: 40px;
  }
  
  .blog .blog-comments .reply-form {
    margin-top: 30px;
    padding: 30px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
  
  .blog .blog-comments .reply-form h4 {
    font-weight: bold;
    font-size: 22px;
  }
  
  .blog .blog-comments .reply-form p {
    font-size: 14px;
  }
  
  .blog .blog-comments .reply-form input {
    border-radius: 4px;
    padding: 10px 10px;
    font-size: 14px;
  }
  
  .blog .blog-comments .reply-form input:focus {
    box-shadow: none;
    border-color: #a0aaf8;
  }
  
  .blog .blog-comments .reply-form textarea {
    border-radius: 4px;
    padding: 10px 10px;
    font-size: 14px;
  }
  
  .blog .blog-comments .reply-form textarea:focus {
    box-shadow: none;
    border-color: #a0aaf8;
  }
  
  .blog .blog-comments .reply-form .form-group {
    margin-bottom: 25px;
  }
  
  .blog .blog-comments .reply-form .btn-primary {
    border-radius: 4px;
    padding: 10px 20px;
    border: 0;
    background-color: #012970;
  }
  
  .blog .blog-comments .reply-form .btn-primary:hover {
    background-color: #013289;
  }
  
  .blog .blog-pagination {
    color: #024ed5;
  }
  
  .blog .blog-pagination ul {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  .blog .blog-pagination li {
    margin: 0 5px;
    transition: 0.3s;
  }
  
  .blog .blog-pagination li a {
    color: #012970;
    padding: 7px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .blog .blog-pagination li.active,
  .blog .blog-pagination li:hover {
    background: #4154f1;
  }
  
  .blog .blog-pagination li.active a,
  .blog .blog-pagination li:hover a {
    color: #fff;
  }
  
  .blog .sidebar {
    padding: 30px;
    margin: 0 0 60px 20px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  }
  
  .blog .sidebar .sidebar-title {
    font-size: 20px;
    font-weight: 700;
    padding: 0 0 0 0;
    margin: 0 0 15px 0;
    color: #012970;
    position: relative;
  }
  
  .blog .sidebar .sidebar-item {
    margin-bottom: 30px;
  }
  
  .blog .sidebar .search-form form {
    background: #fff;
    border: 1px solid #ddd;
    padding: 3px 10px;
    position: relative;
  }
  
  .blog .sidebar .search-form form input[type=text] {
    border: 0;
    padding: 4px;
    border-radius: 4px;
    width: calc(100% - 40px);
  }
  
  .blog .sidebar .search-form form button {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 15px;
    margin: -1px;
    background: #4154f1;
    color: #fff;
    transition: 0.3s;
    border-radius: 0 4px 4px 0;
    line-height: 0;
  }
  
  .blog .sidebar .search-form form button i {
    line-height: 0;
  }
  
  .blog .sidebar .search-form form button:hover {
    background: #5465f2;
  }
  
  .blog .sidebar .categories ul {
    list-style: none;
    padding: 0;
  }
  
  .blog .sidebar .categories ul li+li {
    padding-top: 10px;
  }
  
  .blog .sidebar .categories ul a {
    color: #012970;
    transition: 0.3s;
  }
  
  .blog .sidebar .categories ul a:hover {
    color: #4154f1;
  }
  
  .blog .sidebar .categories ul a span {
    padding-left: 5px;
    color: #aaaaaa;
    font-size: 14px;
  }
  
  .blog .sidebar .recent-posts .post-item+.post-item {
    margin-top: 15px;
  }
  
  .blog .sidebar .recent-posts img {
    width: 80px;
    float: left;
  }
  
  .blog .sidebar .recent-posts h4 {
    font-size: 15px;
    margin-left: 95px;
    font-weight: bold;
  }
  
  .blog .sidebar .recent-posts h4 a {
    color: #012970;
    transition: 0.3s;
  }
  
  .blog .sidebar .recent-posts h4 a:hover {
    color: #4154f1;
  }
  
  .blog .sidebar .recent-posts time {
    display: block;
    margin-left: 95px;
    font-style: italic;
    font-size: 14px;
    color: #aaaaaa;
  }
  
  .blog .sidebar .tags {
    margin-bottom: -10px;
  }
  
  .blog .sidebar .tags ul {
    list-style: none;
    padding: 0;
  }
  
  .blog .sidebar .tags ul li {
    display: inline-block;
  }
  
  .blog .sidebar .tags ul a {
    color: #0257ee;
    font-size: 14px;
    padding: 6px 14px;
    margin: 0 6px 8px 0;
    border: 1px solid #d7e6ff;
    display: inline-block;
    transition: 0.3s;
  }
  
  .blog .sidebar .tags ul a:hover {
    color: #fff;
    border: 1px solid #4154f1;
    background: #4154f1;
  }
  
  .blog .sidebar .tags ul a span {
    padding-left: 5px;
    color: #a5c5fe;
    font-size: 14px;
  }
  

  .serveach{
 
    background-color: #fff;
    /* margin-bottom: 38px; */
    font-size: 17px;
    padding: 4px!important;
    border-width: 2px;
    /* border: 1px solid red; */
    border-color: #4a3aff;
    width: 100%;
    display: grid;
    font-family: League Spartan, sans-serif;
    box-shadow: 0px 0px 12px 3px rgb(19 19 26 / 8%);
    width: 100% !important;

  }
  .sracfsf{
    border: 2px solid;
  
    border-radius: 6px;
    color: blue;
  }
  .scra{
    /* border: 1px solid; */
    margin-bottom: 5px;
    margin-bottom: 38px;
    border-color: #4a3aff;
    text-align: center;
    padding: 5px!important;
    cursor: pointer;
  }
  .stretched-link::after {
position: unset!important;

  }
  #blog{
    margin-top: -112px;
  }
  @media (max-width: 510px) {
  .homeh1 {
    font-size: 45px !important;
    line-height: 55px!important;
  }
}
@media (max-width: 450px) {
  .homeh1 {
    font-size: 32px!important;
    line-height: 44px!important;
  }
}
@media (max-width: 1000px) {
.hero{
    margin-top: -80px;
}
.serviceHover{
    margin-bottom: 20px;
    display: flex;
    display: none;
}
}


@media (max-width: 1200px) {

      .NavbarLogo img {
        position: absolute;
        /* left: -44px; */
        left: -29px;
        top: -38px;
        /* top: -34px; */
        width: 222px;
        height: 97px;
        z-index: 0;
        object-fit: cover;
    }
    /* .NavbarLogo img {
      position: absolute;
      left: -26px;
      top: -22px;
      height: 61px;
      width: 56px;
      object-fit: cover;
     
  } */
    .wor{
      position: absolute;
       left: 72px;
    }
}
/* @media (max-width: 500px) {
.NavbarLogo img {
  position: absolute;
  left: -31px;
  top: -15px;
  height: 47px;
  width: 43px;
  object-fit: cover;
 
}
} */
.contactSect{
  padding: 10px;
  margin-top: -168px;
  margin-bottom: 17px;
}

.sendmsg button{
  background: var(--color-primary);
    border: 0;
    padding: 10px 35px;
    color: #fff;
    transition: 0.4s;
    border-radius: 5px;
    width: 100%;
}

@media (max-width: 992px){
  .contactSect {
    padding: 13px;
    margin-top: -140px;
    margin-bottom: 62px;
}
}
.engImg{
  width: 23px;
  height: 13px;
  margin-right: 8px;
  object-fit: cover;
}
@media (max-width: 1280px) {


.wor{
  position: absolute;
   left: 72px;
}
}
.arrowup{
  position: absolute;
  width: 20px;
  height: 20px;
}
.arrowup {
  display: none;
  position: absolute;
  left: 67%;
  transform: translateX(-50%);
  bottom: -10px;
  transition: opacity 0.3s ease-in-out;
}

.x .arrowup {
  display: block;
  opacity: 0;
}

.x:hover .arrowup {
  opacity: 1;
  /* transform: translateX(67%); */
}


.contact .php-email-form {
  width: 100%;
  width: 400px;
  margin-top: -83px;
  background: #fff;
  /* border: 1px solid rebeccapurple; */
  padding: 35px;
  box-shadow: 0px 0px 8px 0px #cccccc;
  border-radius: 5px;
}

.pdff{
  padding: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 500px) {

.contact .php-email-form {
width: 317px;
}

}
@media (max-width: 350px) {

  .contact .php-email-form {
  width: 100%;
  }
  
  }
  .serv2{
    height: 252px;
  }
  .serv3{
    height: 252px;
  }

  .workhom{
    height: 271px;
  }
  .homeimjh{
    border: 1px solid #e3e2e2;
    border-radius: 43px;
  }
  .abt2{
    /* height: 353px; */
    object-fit: cover;
  }
  @media (min-width: 1100px) and (max-width: 1281px) {
  .navbar ul{
    width: 651px;
  }
  .langcol{
    width: 156px;
    height: 41.6px;
  }
  .xd{
    width: 124px;
  }
}
@media (min-width: 763px) {
.casprep{
 
    height: 372px;
   width: 100vw;
   object-fit: contain;
}
}
.blogfic{
  height: 300px!important;
  object-fit: cover!important;
  width: 100vw;
}
.memname{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.mn img{
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 100%;
}
.xc img {
  object-position: 0px 3px;
}

.xd img {
  object-position: 0px -1px;
}
.mn{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ggf img{
  width: 40px!important;
  height: 40px!important;
}
.pnm{
  margin-top: 10px;
  font-weight: bold;
  color: #183c78;
}
@media (min-width: 0px) and (max-width: 650px) {
  .memname{
   flex-direction: column;
  }
  .mn{
    margin-top: 23px;
  }
  #team{
    padding: 0px;
  }
}